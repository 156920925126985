import deliveryMethod from "../redux/reducers/DeliveryMethodReducer";
import { getProductPrice, getProductsToShow } from "./product";
import moment from "moment";

const calculateModifiersTotal = (
  modifiersData,
  modifiersCurrent,
  productSize,
  sizeVariants,
  deliveryMethod,
  appSource
) => {
  let modifiersTotal = 0;
  if (modifiersData && modifiersCurrent && modifiersData?.length > 0 && modifiersCurrent?.length > 0) {
    modifiersData?.forEach((modifier) => {
      modifiersCurrent
        ?.find((mod) => {
          return mod?.uuid === modifier?.uuid;
        })
        ?.value?.forEach((value) => {
          if (value?.item) {
            modifiersTotal =
              modifiersTotal +
              parseInt(
                modifier?.productModifiers?.find((productModifier) => {
                  return productModifier?.modifier?.uuid === value?.item;
                })?.price[
                appSource === "dine-in" ||
                  appSource === "pay" ||
                  appSource === "QR"
                  ? "dineIn"
                  : deliveryMethod
                ]
              ) *
              value?.qty;
          }
        });
    });
  }
  let sizePrice = 0;

  if (productSize && sizeVariants && sizeVariants?.length > 0) {
    sizePrice =
      sizeVariants[productSize - 1]?.price[
      appSource === "dine-in" || appSource === "pay" || appSource === "QR"
        ? "dineIn"
        : deliveryMethod
      ];
    modifiersTotal += parseInt(sizePrice);
  }

  return {
    modifiersTotal: modifiersTotal,
    modifiersTotalExcludingSize: sizePrice && sizePrice > 0 ? modifiersTotal - sizePrice : modifiersTotal
  }
}

export const calculateTotalPrice = (
  productDetails,
  product,
  deliveryMethod,
  cartDeliveryMethod,
  cartBranch,
  dineInSelectedBranch,
  paySelectedBranch,
  QRSelectedBranch,
  appSource,
) => {
  let modifiersTotal = 0;
  let modifiersTotalData;

  if (product?.type === "combo" && product?.combo && typeof product?.combo === "object" && Object.keys(product?.combo).length > 0) {
    Object.values(product?.combo)?.forEach((comboGroup) => {
      comboGroup?.combo?.forEach((comboItem) => {
        const comboItemModifiersTotal = calculateModifiersTotal(
          comboItem?.modifiers?.data,
          comboItem?.modifiers?.modifiersCurrent,
          comboItem?.size,
          null,
          deliveryMethod,
          appSource
        )?.modifiersTotal;

        modifiersTotal += (comboItemModifiersTotal * comboItem?.qty);
      })
    })
  } else {
    modifiersTotalData = calculateModifiersTotal(
      product?.modifiers?.data,
      product?.modifiers?.modifiersCurrent,
      product?.size,
      productDetails?.size?.variants,
      deliveryMethod,
      appSource
    )
    modifiersTotal = modifiersTotalData?.modifiersTotal
  }
  const priceData = getProductPrice(
    cartDeliveryMethod,
    productDetails?.finances,
    cartBranch,
    cartBranch,
    productDetails?.discount,
    dineInSelectedBranch,
    paySelectedBranch,
    QRSelectedBranch,
    appSource,
    modifiersTotal,
    modifiersTotalData?.modifiersTotalExcludingSize
  );

  let price =
    priceData?.priceAfterDiscount !== null &&
      priceData?.priceAfterDiscount !== undefined
      ? priceData?.priceAfterDiscount
      : priceData?.originalPrice;

  let originalPrice =
    priceData?.priceAfterDiscount || priceData?.priceAfterDiscount === 0
      ? priceData?.originalPrice
      : null;

  return {
    price: price,
    originalPrice: originalPrice,
    modifiers: priceData?.modifiers
    // discount: priceData?.discount,
  };
};

export const getCartDetails = (
  cart,
  products,
  deliveryMethod,
  cartDeliveryMethod,
  cartBranch,
  dineInSelectedBranch,
  paySelectedBranch,
  QRSelectedBranch,
  appSource
) => {
  let details = [];
  cart.forEach((item) => {
    let pDetails = products?.find((product) => {
      return product?.uuid === item?.uuid;
    });
    details.push({
      ...item,
      details: pDetails,
      price: calculateTotalPrice(
        pDetails,
        item,
        deliveryMethod,
        cartDeliveryMethod,
        cartBranch,
        dineInSelectedBranch,
        paySelectedBranch,
        QRSelectedBranch,
        appSource
      ),
    });
  });
  return details;
};

export const getPercentageDiscountsCartTotals = (cartDetails, subtotal) => {
  let cartDiscounts = [];
  cartDetails?.forEach((item) => {
    if (
      item?.price?.discount?.uuid &&
      item?.price?.originalPrice &&
      item?.price?.discount?.type === "Percentage"
    ) {
      const minBillAmount = item?.details?.discount[0]?.minDiscountAmount;
      const maxDiscountAmount = item?.details?.discount[0]?.maxDiscountAmount;
      const discountData = cartDiscounts?.find((disc) => {
        return disc?.uuid === item?.price?.discount?.uuid;
      });
      if (!discountData) {
        cartDiscounts.push({
          uuid: item?.price?.discount?.uuid,
          total: item?.price?.originalPrice * item?.qty,
          totalDiscount:
            minBillAmount && subtotal?.subtotalBeforeDiscount < minBillAmount
              ? 0
              : maxDiscountAmount
                ? (item?.price?.originalPrice - item?.price?.price) * item?.qty >
                  maxDiscountAmount
                  ? maxDiscountAmount
                  : (item?.price?.originalPrice - item?.price?.price) * item?.qty
                : (item?.price?.originalPrice - item?.price?.price) * item?.qty,
        });
      } else {
        cartDiscounts = cartDiscounts?.map((cartDiscount) => {
          if (cartDiscount?.uuid === item?.price?.discount?.uuid) {
            return {
              ...cartDiscount,
              total:
                cartDiscount?.total + item?.price?.originalPrice * item?.qty,
              totalDiscount:
                minBillAmount &&
                  subtotal?.subtotalBeforeDiscount < minBillAmount
                  ? 0
                  : maxDiscountAmount
                    ? (item?.price?.originalPrice - item?.price?.price) *
                      item?.qty +
                      cartDiscount.totalDiscount >
                      maxDiscountAmount
                      ? maxDiscountAmount
                      : (item?.price?.originalPrice - item?.price?.price) *
                      item?.qty +
                      cartDiscount.totalDiscount
                    : (item?.price?.originalPrice - item?.price?.price) *
                    item?.qty +
                    cartDiscount.totalDiscount,
            };
          } else {
            return {
              ...cartDiscount,
            };
          }
        });
      }
    }
  });
  return cartDiscounts;
};

export const calculateCartSubtotal = (
  cart,
  products,
  deliveryMethod,
  cartDeliveryMethod,
  cartBranch,
  dineInSelectedBranch,
  paySelectedBranch,
  QRSelectedBranch,
  appSource,
  pointsUsed = []
) => {
  let subtotal = 0;
  let subtotalBeforeDiscount = 0;
  let amountRedeemedWithPoints = 0;

  const details = getCartDetails(
    cart,
    products,
    deliveryMethod,
    cartDeliveryMethod,
    cartBranch,
    dineInSelectedBranch,
    paySelectedBranch,
    QRSelectedBranch,
    appSource,
  );

  details?.forEach((row) => {
    //check if points are used for the item  
    let amountDiscounted = 0;

    if (pointsUsed?.length > 0) {
      const pointsUsedForItemFound = pointsUsed?.find((ptsUsed) => {
        return ptsUsed?.additionTime === row?.additionTime
      })

      if (pointsUsedForItemFound) {
        //calculate amount discounted
        amountDiscounted = (pointsUsedForItemFound?.qtyToBeRedeemed || 0) * (row?.price?.price - (row?.price?.originalPrice && row?.price?.modifiers?.discountedAmount ? row?.price?.modifiers?.originalPrice - row?.price?.modifiers?.discountedAmount : row?.price?.modifiers?.originalPrice ? row?.price?.modifiers?.originalPrice : 0));

        amountRedeemedWithPoints += amountDiscounted;
      }
    }

    subtotal += (row?.price?.price * row?.qty) - amountDiscounted;

    if (row?.price?.originalPrice) {
      subtotalBeforeDiscount += row?.price?.originalPrice * row?.qty;
    } else {
      subtotalBeforeDiscount += row?.price?.price * row?.qty;
    }
  });
  // const percentageDiscountsCartTotals = getPercentageDiscountsCartTotals(
  //   details,
  //   { subtotal: subtotal, subtotalBeforeDiscount: subtotalBeforeDiscount }
  // );

  return {
    subtotal: subtotal,
    subtotalBeforeDiscount: subtotalBeforeDiscount,
    amountRedeemedWithPoints: amountRedeemedWithPoints
    // subtotalBeforeDiscount > 0 ? subtotalBeforeDiscount : null,
  };
};

export const getCartItemsToBeUpdated = (
  cart,
  products,
  cartDeliveryMethod,
  deliveryMethod,
  userSelectedBranch,
  deliverySelectedBranch,
  cartBranch,
  source = null,
  dineInSelectedBranch,
  paySelectedBranch,
  QRSelectedBranch,
  appSource
) => {
  let productsToShow = [];

  if (
    source &&
    (source === "method-change" || source === "user-selected-branch-change")
  ) {
    productsToShow = getProductsToShow(
      products,
      deliveryMethod,
      deliverySelectedBranch,
      userSelectedBranch,
      dineInSelectedBranch,
      paySelectedBranch,
      QRSelectedBranch,
      appSource
    );
    // } else if(source && source === "user-selected-branch-change") {
    //   productsToShow = getProductsToShow (
    //     products,
    //     cartDeliveryMethod,
    //     cartBranch,
    //     userSelectedBranch
    //   )
  } else {
    productsToShow = getProductsToShow(
      products,
      deliveryMethod,
      cartBranch,
      userSelectedBranch,
      dineInSelectedBranch,
      paySelectedBranch,
      QRSelectedBranch,
      appSource
    );
  }

  let someItemsNotAvailable = [];

  if (
    source &&
    (source === "method-change" || source === "user-selected-branch-change")
  ) {
    someItemsNotAvailable = cart?.filter((item) => {
      return products?.find((product) => {
        return (
          product?.uuid === item?.uuid &&
          !(
            product?.finances?.find((finance) => {
              return (
                finance?.defaultItemPrice &&
                finance?.finance[
                  appSource === "dine-in" ||
                    appSource === "pay" ||
                    appSource === "QR"
                    ? "dineIn"
                    : deliveryMethod
                ]?.available
              );
            }) &&
            product?.finances?.find((finance) => {
              return (
                finance?.branch?.uuid ===
                (deliveryMethod === "delivery"
                  ? deliverySelectedBranch
                  : userSelectedBranch) &&
                finance?.finance[
                  appSource === "dine-in" ||
                    appSource === "pay" ||
                    appSource === "QR"
                    ? "dineIn"
                    : deliveryMethod
                ]?.available
              );
            })
          )
        );
      });
    });
  } else {
    someItemsNotAvailable = cart?.filter((item) => {
      return ((products?.find((product) => {
        return (
          product?.uuid === item?.uuid &&
          !(
            product?.finances?.find((finance) => {
              return (
                finance?.defaultItemPrice &&
                finance?.finance[cartDeliveryMethod]?.available
              );
            }) &&
            product?.finances?.find((finance) => {
              return (
                finance?.branch?.uuid === cartBranch &&
                finance?.finance[cartDeliveryMethod]?.available
              );
            })
          )
        );
      })
      )
        ||
        (products?.findIndex((pr) => {
          return pr?.uuid === item?.uuid
        }) === -1)
      )
    });
  }

  let someItemsWithDifferentPrices = [];

  if (source && source === "method-change") {
    someItemsWithDifferentPrices = cart?.filter((item) => {
      return productsToShow?.find((product) => {
        const newPriceData = getProductPrice(
          // deliveryMethod,
          // product?.finances,
          // userSelectedBranch,
          // deliverySelectedBranch,
          // product?.discount,

          deliveryMethod,
          product?.finances,
          userSelectedBranch,
          deliverySelectedBranch,
          product?.discount,
          dineInSelectedBranch,
          paySelectedBranch,
          QRSelectedBranch,
          appSource
        );
        const newPrice = newPriceData?.priceAfterDiscount
          ? newPriceData?.priceAfterDiscount
          : newPriceData?.originalPrice;

        const oldPriceData = getProductPrice(
          // cartDeliveryMethod,
          // product?.finances,
          // userSelectedBranch,
          // deliverySelectedBranch,
          // product?.discount
          cartDeliveryMethod,
          product?.finances,
          userSelectedBranch,
          deliverySelectedBranch,
          product?.discount,

          dineInSelectedBranch,
          paySelectedBranch,
          QRSelectedBranch,
          appSource
        );
        const oldPrice = oldPriceData?.priceAfterDiscount
          ? oldPriceData?.priceAfterDiscount
          : oldPriceData?.originalPrice;
        return (
          product?.uuid === item?.uuid &&
          newPrice !== oldPrice &&
          !someItemsNotAvailable?.find((itemNotAvailable) => {
            return itemNotAvailable?.uuid === item?.uuid;
          })
        );
      });
    });
  } else if (source && source === "user-selected-branch-change") {
    someItemsWithDifferentPrices = cart?.filter((item) => {
      return productsToShow?.find((product) => {
        const newPriceData = getProductPrice(
          // cartDeliveryMethod,
          // product?.finances,
          // userSelectedBranch,
          // deliverySelectedBranch,
          // product?.discount,
          cartDeliveryMethod,
          product?.finances,
          userSelectedBranch,
          deliverySelectedBranch,
          product?.discount,
          dineInSelectedBranch,
          paySelectedBranch,
          QRSelectedBranch,
          appSource
        );
        const newPrice = newPriceData?.priceAfterDiscount
          ? newPriceData?.priceAfterDiscount
          : newPriceData?.originalPrice;

        const oldPriceData = getProductPrice(
          // cartDeliveryMethod,
          // product?.finances,
          // cartBranch,
          // deliverySelectedBranch,
          // product?.discount
          cartDeliveryMethod,
          product?.finances,
          cartBranch,
          deliverySelectedBranch,
          product?.discount,

          dineInSelectedBranch,
          paySelectedBranch,
          QRSelectedBranch,
          appSource
        );

        const oldPrice = oldPriceData?.priceAfterDiscount
          ? oldPriceData?.priceAfterDiscount
          : oldPriceData?.originalPrice;
        return (
          product?.uuid === item?.uuid &&
          newPrice !== oldPrice &&
          !someItemsNotAvailable?.find((itemNotAvailable) => {
            return itemNotAvailable?.uuid === item?.uuid;
          })
        );
      });
    });
  } else {
    someItemsWithDifferentPrices = cart?.filter((item) => {
      return productsToShow?.find((product) => {
        const newPriceData = getProductPrice(
          // cartDeliveryMethod,
          // product?.finances,
          // userSelectedBranch,
          // deliverySelectedBranch,
          // product?.discount
          cartDeliveryMethod,
          product?.finances,
          userSelectedBranch,
          deliverySelectedBranch,
          product?.discount,

          dineInSelectedBranch,
          paySelectedBranch,
          QRSelectedBranch,
          appSource
        );

        const newPrice = newPriceData?.priceAfterDiscount
          ? newPriceData?.priceAfterDiscount
          : newPriceData?.originalPrice;

        const oldPriceData = getProductPrice(
          // cartDeliveryMethod,
          // product?.finances,
          // cartBranch,
          // cartBranch,
          // product?.discount
          cartDeliveryMethod,
          product?.finances,
          cartBranch,
          cartBranch,
          product?.discount,

          dineInSelectedBranch,
          paySelectedBranch,
          QRSelectedBranch,
          appSource
        );

        const oldPrice = oldPriceData?.priceAfterDiscount
          ? oldPriceData?.priceAfterDiscount
          : oldPriceData?.originalPrice;

        return (
          product?.uuid === item?.uuid &&
          newPrice !== oldPrice &&
          !someItemsNotAvailable?.find((itemNotAvailable) => {
            return itemNotAvailable?.uuid === item?.uuid;
          })
        );
      });
    });
  }

  return { someItemsNotAvailable, someItemsWithDifferentPrices };
};

// function to update cart when delivery branch changes
export const updateCartItems = (
  cart,
  products,
  deliveryMethod,
  deliverySelectedBranch,
  userSelectedBranch,
  cartDeliveryMethod,
  isQR,
  cartBranch,
  removeFromCart,
  source = null,
  dineInSelectedBranch,
  paySelectedBranch,
  QRSelectedBranch,
  appSource
) => {
  const itemsTobUpdated = getCartItemsToBeUpdated(
    cart,
    products,
    cartDeliveryMethod,
    deliveryMethod,
    userSelectedBranch,
    deliverySelectedBranch,
    cartBranch,
    source,
    dineInSelectedBranch,
    paySelectedBranch,
    QRSelectedBranch,
    appSource
  );

  const someItemsNotAvailable = itemsTobUpdated?.someItemsNotAvailable;

  if (someItemsNotAvailable?.length > 0) {
    cart?.forEach((item) => {
      const itemIndex = someItemsNotAvailable?.findIndex((itemNotAvailable) => {
        return itemNotAvailable?.uuid === item?.uuid;
      });

      if (itemIndex > -1) {
        removeFromCart(item?.additionTime, isQR);
      }
    });
  }

  const someItemsWithDifferentPrices =
    itemsTobUpdated?.someItemsWithDifferentPrices;

  return (
    someItemsNotAvailable?.length > 0 ||
    someItemsWithDifferentPrices?.length > 0
  );
};

export const handleDeliveryMethodChange = async (
  method,
  setOpenNoDeliveryClearCartConfirm,
  setOpenNoDeliveryToLocationOptions,
  setOpenNoDeliveryToLocationDeliverySwitchOptions,

  setOpenItemsNotAvailableAndPricesChangeConfirm,
  setOpenItemsNotAvailableConfirm,
  setOpenPricesChangesConfirm,

  // DATA
  currentAddress,
  cart,
  products,
  cartDeliveryMethod,
  deliverySelectedBranch,
  userSelectedBranch,
  cartBranch,
  selectedRestaurant,
  lat,
  lng,
  pickupBranches,
  dineInSelectedBranch,
  paySelectedBranch,
  QRSelectedBranch,
  appSource,

  // ACTIONS
  updateCartDetail,
  changeDeliveryMethod,
  getNearestDeliveryBranch,
  setDeliverySelectedBranch
) => {
  // if((method !== cartDeliveryMethod && cart?.length > 0) || (method !== deliveryMethod && cart?.length === 0)) {

  // if user is switching from pickup to delivery
  if (
    (method !== cartDeliveryMethod &&
      cart?.length > 0 &&
      method === "delivery" &&
      cartDeliveryMethod === "pickUp") ||
    (method !== deliveryMethod && cart?.length === 0 && method === "delivery")
  ) {
    if (!currentAddress) {
      updateCartDetail("cartDeliveryMethod", method);
      updateCartDetail(
        "cartBranch",
        method === "delivery" ? deliverySelectedBranch : userSelectedBranch
      );
      changeDeliveryMethod(method);
      return;
    }
    //should get the nearest branch before here again to make sure if there is delivery
    const deliveryCb = (nearestBranch) => {
      //check if any items in the cart are not available for pickup, in the selected branch
      if (cart?.length > 0) {
        const { someItemsNotAvailable, someItemsWithDifferentPrices } =
          getCartItemsToBeUpdated(
            cart,
            products,
            cartDeliveryMethod,
            method,
            userSelectedBranch,
            nearestBranch,
            cartBranch,
            "method-change",
            dineInSelectedBranch,
            paySelectedBranch,
            QRSelectedBranch,
            appSource
          );

        if (
          someItemsNotAvailable?.length > 0 &&
          someItemsWithDifferentPrices?.length > 0
        ) {
          setOpenItemsNotAvailableAndPricesChangeConfirm(true);
          return;
        }
        if (someItemsNotAvailable?.length > 0) {
          setOpenItemsNotAvailableConfirm(true);
          return;
        }
        if (someItemsWithDifferentPrices?.length > 0) {
          setOpenPricesChangesConfirm(true);
          return;
        }
        // else, if nothing will be updated in the cart, swich to delivery
        updateCartDetail("cartDeliveryMethod", "delivery");
        updateCartDetail("cartBranch", nearestBranch);
        setDeliverySelectedBranch(nearestBranch);
        changeDeliveryMethod("delivery");
      } else {
        // if cart is already empty, switch to delivery and set the delivery branch to the nearest branch
        setDeliverySelectedBranch(nearestBranch);
        changeDeliveryMethod("delivery");
      }
    };
    const noDeliveryCb = () => {
      if (cart?.length > 0) {
        setOpenNoDeliveryClearCartConfirm(true);
      } else {
        setOpenNoDeliveryToLocationDeliverySwitchOptions(true);
      }
    };
    await getNearestDeliveryBranch(
      selectedRestaurant,
      lng,
      lat,
      deliveryCb,
      noDeliveryCb
    );
    return;
  }

  // if switching from delivery to pickup

  // first check if there is any user selected branch, if not, use a default
  let userBranch = userSelectedBranch;
  if (userSelectedBranch === "") {
    userBranch = pickupBranches[0]?.uuid;
  } else {
    const selectedBranchExists = pickupBranches?.find((branch) => {
      return branch?.uuid === userSelectedBranch;
    });
    if (!selectedBranchExists) {
      userBranch = pickupBranches[0]?.uuid;
    }
  }
  const { someItemsNotAvailable, someItemsWithDifferentPrices } =
    getCartItemsToBeUpdated(
      cart,
      products,
      cartDeliveryMethod,
      method,
      userBranch,
      deliverySelectedBranch,
      cartBranch,
      "method-change",
      dineInSelectedBranch,
      paySelectedBranch,
      QRSelectedBranch,
      appSource
    );

  if (
    someItemsNotAvailable?.length > 0 &&
    someItemsWithDifferentPrices?.length > 0
  ) {
    setOpenItemsNotAvailableAndPricesChangeConfirm(true);
    return;
  }
  if (someItemsNotAvailable?.length > 0) {
    setOpenItemsNotAvailableConfirm(true);
    return;
  }
  if (someItemsWithDifferentPrices?.length > 0) {
    setOpenPricesChangesConfirm(true);
    return;
  }
  //else
  updateCartDetail("cartDeliveryMethod", method);
  updateCartDetail(
    "cartBranch",
    method === "delivery" ? deliverySelectedBranch : userSelectedBranch
  );

  changeDeliveryMethod(method);
};

export const checkQRCartExpiration = (QRCart, appSource, clearCart, clearFilters) => {
  const CART_TIMEOUT = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
  let now = moment().valueOf();
  let lastAdditionTime = QRCart?.reduce((latest, item) => {
    return Math.max(latest, item.additionTime);
  }, 0);
  if (now - lastAdditionTime > CART_TIMEOUT) {
    clearCart(appSource);
    if (appSource === "QR") {
      clearFilters(appSource);
    }
  }
};

export const cartDraftProceed = (cartData, appSelectedRestaurant, cartDraftUseStart, cartDraftUseEnd, addToCart) => {
  cartDraftUseStart();
  cartData?.forEach((item, index) => {
    let itemData = {
      ...item,
      additionTime: null,
    };

    setTimeout(() => {
      itemData.additionTime = moment().valueOf(); // set additionTime after a delay
      addToCart(itemData, false);
      if (index === cartData?.length - 1) {
        setTimeout(() => {
          cartDraftUseEnd(appSelectedRestaurant);
        }, 10);
      }
    }, 10);
    //   }
  });
};


export const updateCartUsedPoints = (cart, products, deliveryMethod, cartDeliveryMethod, cartBranch, dineInSelectedBranch, paySelectedBranch, QRSelectedBranch, appSource, cartPointsData, token, userPoints, restoBranchHasLoyalty, resetPointsData, removePointsUsed, updatePointsUsed) => {
  const details = getCartDetails(
    cart,
    products,
    deliveryMethod,
    cartDeliveryMethod,
    cartBranch,
    dineInSelectedBranch,
    paySelectedBranch,
    QRSelectedBranch,
    appSource
  );
  //update points selected on cart load, if any updates
  //if no points data or if user is not logged in, return
  if (!cartPointsData || !token) {
    return;
  }
  //check if newly selected branch has loyalty, if not, reset
  if (!restoBranchHasLoyalty) {
    console.log('heererr')
    resetPointsData(appSource);
  }
  //else, update
  //check original points that user had when he first selected his points
  if (userPoints != cartPointsData?.originalPoints) {
    resetPointsData(appSource);
    return
  }
  //check if points to redeem each item are now greater than the number used by user before, if so, reset selected points for the item
  if (cartPointsData?.pointsUsed && cartPointsData?.pointsUsed?.length > 0) {
    cartPointsData?.pointsUsed?.forEach((pointsUsedData) => {
      const item = details?.find((itm) => {
        return itm?.additionTime === pointsUsedData?.additionTime
      })
      //check if no more loyalty on item, remove points
      const itemLoyaltyProduct = item?.details?.loyaltyProduct;

      if (!(itemLoyaltyProduct && itemLoyaltyProduct?.isActive)) {
        removePointsUsed(pointsUsedData?.additionTime, appSource);
        return;
      }
      //else, get updated points to redeem item
      let updatedPointsToRedeemItem = 0;

      if (item?.size !== null && item?.size >= 0) {
        if (itemLoyaltyProduct?.size && itemLoyaltyProduct?.size?.length > 0) {
          if (itemLoyaltyProduct?.size[item?.size - 1]?.point) {
            updatedPointsToRedeemItem = Number(itemLoyaltyProduct?.size[item.size - 1]?.point);
          }
        }
      }
      //else, if no size is specified, use points of loyaltyl if any
      else {
        if (itemLoyaltyProduct?.point && itemLoyaltyProduct?.point > 0) {
          updatedPointsToRedeemItem = Number(itemLoyaltyProduct?.point);
        }
      }
      if (Number(pointsUsedData?.oneItemPointsToBeUsed) < updatedPointsToRedeemItem) {
        removePointsUsed(pointsUsedData?.additionTime, appSource);
        return;
      }
      //if item loyalty points was updated, but is below previously used by user, update the points selected to match new updated value
      if (Number(pointsUsedData?.oneItemPointsToBeUsed) > updatedPointsToRedeemItem) {
        updatePointsUsed(pointsUsedData?.additionTime, {
          ...pointsUsedData,
          oneItemPointsToBeUsed: updatedPointsToRedeemItem
        }, appSource)
      }
    })
  }
}