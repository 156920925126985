// function to get product price, based on delivery method, selected branch and activated modules for the product and branch
export const getProductPrice = (
  method,
  finances,
  userBranch,
  deliverySelectedBranch,
  discount,
  dineInSelectedBranch,
  paySelectedBranch,
  QRSelectedBranch,
  appSource,
  modifiersTotal = 0,
  modifiersTotalExcludingSize = null
) => {
  let deliveryMethod, userSelectedBranch;

  if (appSource === "dine-in") {
    deliveryMethod = "dineIn";
    userSelectedBranch = dineInSelectedBranch;
  } else if (appSource === "pay") {
    deliveryMethod = "dineIn";
    userSelectedBranch = paySelectedBranch;
  } else if (appSource === "QR") {
    deliveryMethod = "dineIn";
    userSelectedBranch = QRSelectedBranch;
  } else {
    deliveryMethod = method;
    userSelectedBranch = userBranch;
  }

  let price;

  const defaultPriceValue = finances?.find((finance) => {
        return (
          finance?.defaultItemPrice === true &&
          finance?.finance?.[deliveryMethod]?.available === true
        );
      })?.finance?.[deliveryMethod]?.price;

  const defaultPrice = defaultPriceValue && defaultPriceValue > 0 ? defaultPriceValue : 0;

  //CHECK PRICE AND AVAILABILITY FOR THE DELIVERY METHOD AND THE BRANCH, IF NO PRICE ASSOCIATED WITH THE DELIVERY METHOD AND THE BRANCH, USE THE DEFAULT PRICE

    if (deliveryMethod === "delivery") {
      if (deliverySelectedBranch !== "") {

        const deliveryBranchData = finances?.find((finance) => {
          return finance?.finance?.["delivery"]?.available &&
                 finance?.branch?.uuid === deliverySelectedBranch
        })

        if (deliveryBranchData && deliveryBranchData?.finance?.["delivery"]?.price) {
          price = deliveryBranchData?.finance?.["delivery"]?.price;
        } else {
        //handle when no data available (either no price set or not available for those options) 
          price = defaultPrice;
        } 
      } else {
        price = defaultPrice;
      }
    }

  //CHECK PRICE AMD AVAILABILITY FOR THE PICKUP/DINE IN METHODS AND THE BRANCH SELECTED BY USER, IF NO PRICE ASSOCIATED WITH THE PICKUP/DINE IN METHODS AND THE BRANCH, USE THE DEFAULT PRICE

  if (deliveryMethod === "pickUp" || deliveryMethod === "dineIn") {
    if (userSelectedBranch !== "") {

      const pickUpOrDineInBranchData = finances?.find((finance) => {
        return finance?.finance?.[deliveryMethod]?.available &&
               finance?.branch?.uuid === userSelectedBranch
      })

      if (pickUpOrDineInBranchData && pickUpOrDineInBranchData?.finance?.[deliveryMethod]?.price) {
        price = pickUpOrDineInBranchData?.finance?.[deliveryMethod]?.price;
      } else {
      //handle when no data available (either no price set or not available for those options) 
        price = defaultPrice;
      } 
    } else {
      price = defaultPrice;
    }
  }

  //  let price = deliveryMethod === "delivery" && deliverySelectedBranch !== ""
  //     ? finances?.find((finance) => {
  //       return (
  //         finance?.finance?.[deliveryMethod]?.available &&
  //         finance?.branch?.uuid === deliverySelectedBranch
  //       );
  //     })?.finance?.[deliveryMethod]?.price
  //     : (deliveryMethod === "delivery" && deliverySelectedBranch === "") ||
  //       ((deliveryMethod === "pickUp" || deliveryMethod === "dineIn") &&
  //         userSelectedBranch === "")
  //       ? finances?.find((finance) => {
  //         return (
  //           finance?.finance?.[deliveryMethod]?.available &&
  //           finance?.defaultItemPrice
  //         );
  //       })?.finance?.[deliveryMethod]?.price
  //       : (deliveryMethod === "pickUp" || deliveryMethod === "dineIn") &&
  //         userSelectedBranch !== ""
  //         ? finances?.find((finance) => {
  //           return (
  //             finance?.branch?.uuid === userSelectedBranch &&
  //             finance?.finance?.[deliveryMethod]?.available
  //           );
  //         })?.finance?.[deliveryMethod]?.price
  //         : 0;
  // if (price === 0) {
  //   const defaultPrice = finances?.find((finance) => {
  //     return (
  //       finance?.defaultItemPrice === true &&
  //       finance?.finance?.[deliveryMethod]?.available === true
  //     );
  //   })?.finance?.[deliveryMethod]?.price;
  //   price = defaultPrice && defaultPrice > 0 ? defaultPrice : 0;
  // }


  //if price && price is not 0 (is set)
  let discountedAmount;
  let discountUuid;
  let discountType;

  if (discount && discount?.length > 0) {
    const selectedBranch =
      appSource === "QR" ||
        appSource === "dine-in" ||
        appSource === "pay" ||
        (appSource === "APP" &&
          ((deliveryMethod && deliveryMethod === "dineIn") ||
            deliveryMethod === "pickUp"))
        ? userSelectedBranch
        : appSource === "APP" && deliveryMethod === "delivery"
          ? deliverySelectedBranch
          : "";

    const isBranchExcluded = discount[0]?.discountExcludedBranch?.find(
      (excludedBranch) => {
        return excludedBranch?.branch?.uuid === selectedBranch;
      }
    );

    if (!isBranchExcluded) {
      if (
        discount[0]?.discountType === "Percentage" &&
        discount[0]?.percentage > 0
      ) {
        discountedAmount =
          (price + modifiersTotal) * (discount[0]?.percentage / 100);
      } else if (
        discount[0]?.discountType === "Amount" &&
        discount[0]?.percentage > 0
      ) {
        discountedAmount = discount[0]?.percentage;
      }
    }
    discountUuid = discount[0]?.discountUuid;
    discountType = discount[0]?.discountType;
  }

  const priceAfterDiscount = discountedAmount
    ? price + modifiersTotal - (discountedAmount > (price + modifiersTotal) ? (price + modifiersTotal) : discountedAmount)
    : null;

  const modifiersTotalValue = modifiersTotalExcludingSize !== null ? modifiersTotalExcludingSize : modifiersTotal;

  const result = {
    originalPrice: price + modifiersTotal,
    priceAfterDiscount: priceAfterDiscount,
    modifiers: {
      originalPrice: modifiersTotalValue,
      discountedAmount: priceAfterDiscount && modifiersTotalValue > 0 ? ((modifiersTotalValue / (modifiersTotal + price))) * discountedAmount : null
    }
    // discount: {
    //   uuid: discountUuid,
    //   type: discountType,
    // },
  };
  return result;
};

//function to get product price range
export const getProductPriceRange = (productPrice, product, deliveryMethod) => {
  if (productPrice && productPrice > 0) {
    return null; // Return null if productPrice exists and is not zero
  }

  // Fetch variants and discounts from the product object
  const variants = product?.size?.variants;
  const discounts = product?.discount;

  // Check if at least one variant has a price greater than 0 for the given delivery method

  const hasPriceGreaterThanZero = variants?.some(variant => {
    const deliveryPrice = parseFloat(variant?.price[deliveryMethod]);
    return !isNaN(deliveryPrice) && deliveryPrice > 0;
  });

  if (!hasPriceGreaterThanZero) {
    return null; // Return null if no variant has a price greater than 0 for the delivery method
  }

  let originalMin = Infinity;
  let originalMax = -Infinity;
  let afterDiscountMin = Infinity;
  let afterDiscountMax = -Infinity;

  variants.forEach(variant => {
    const variantPrice = parseFloat(variant.price[deliveryMethod]);

    // Consider prices greater than zero for calculation
    if (variantPrice > 0) {
      originalMin = Math.min(originalMin, variantPrice);
      originalMax = Math.max(originalMax, variantPrice);

      if (discounts && discounts.length > 0) {
        const discount = discounts[0]; // Assuming only one discount is considered

        let priceAfterDiscount;
        if (discount.discountType === 'Percentage') {
          const discountValue = variantPrice * (discount.percentage / 100);
          priceAfterDiscount = variantPrice - discountValue;
        } else if (discount.discountType === 'Amount') {
          priceAfterDiscount = variantPrice - discount.percentage;
        }

        afterDiscountMin = Math.min(afterDiscountMin, priceAfterDiscount);
        afterDiscountMax = Math.max(afterDiscountMax, priceAfterDiscount);
      }
    }
  });

  // Return the price range object
  return {
    original: { min: originalMin, max: originalMax },
    afterDiscount: { min: afterDiscountMin, max: afterDiscountMax },
  };

};

// export const getProductPrice = (deliveryMethod, finances, userSelectedBranch, deliverySelectedBranch) => {

//    let price = deliveryMethod === "delivery" && deliverySelectedBranch !== "no-delivery" ? finances?.find((finance)=> {return finance?.finance?.[deliveryMethod]?.available && finance?.branch?.uuid === deliverySelectedBranch})?.finance?.[deliveryMethod]?.price : (deliveryMethod === "delivery" && deliverySelectedBranch === "" || ((deliveryMethod === "pickUp" || deliveryMethod === "dineIn") &&
//    userSelectedBranch === "")) ? finances?.find((finance)=> {return finance?.finance?.[deliveryMethod]?.available && finance?.defaultItemPrice})?.finance?.[deliveryMethod]?.price : ((deliveryMethod === "pickUp" || deliveryMethod === "dineIn") && userSelectedBranch !== "") ? finances?.find((finance) => {return finance?.branch?.uuid === userSelectedBranch && finance?.finance?.[deliveryMethod]?.available})?.finance?.[deliveryMethod]?.price : 0;
//    if(price === 0) {
//     const defaultPrice = finances?.find((finance) => {
//       return (
//         finance?.defaultItemPrice === true && finance?.finance?.[deliveryMethod]?.available === true
//       )
//     })?.finance?.[deliveryMethod]?.price
//     price = defaultPrice && defaultPrice > 0 ? defaultPrice : 0;
//    }
//    return price;
// }

//function to get products to show, or popular items to show
export const getProductsToShow = (
  products,
  method,
  deliverySelectedBranch,
  userBranch,
  dineInSelectedBranch,
  paySelectedBranch,
  QRSelectedBranch,
  appSource
) => {
  if (!method) {
    return;
  }

  let deliveryMethod, userSelectedBranch;
  if (appSource === "dine-in") {
    deliveryMethod = "dineIn";
    userSelectedBranch = dineInSelectedBranch;
  } else if (appSource === "pay") {
    deliveryMethod = "dineIn";
    userSelectedBranch = paySelectedBranch;
  } else if (appSource === "QR") {
    deliveryMethod = "dineIn";
    userSelectedBranch = QRSelectedBranch;
  } else if (appSource === "APP") {
    deliveryMethod = method;
    userSelectedBranch = userBranch;
  }

  let productsToShowArray = [];
  if (products?.length > 0) {
    //if delivery method is delivery
    if (deliveryMethod === "delivery") {
      //and delivery selected branch
      if (deliverySelectedBranch && deliverySelectedBranch !== "") {
        productsToShowArray = [...products]?.filter((product) => {
          return (
            product?.isActive &&
            product?.finances?.find((finance) => {
              return (
                finance?.branch?.uuid === deliverySelectedBranch &&
                finance?.finance?.[deliveryMethod]?.available === true
              );
            }) &&
            product?.finances?.find((finance) => {
              return (
                finance?.defaultItemPrice &&
                finance?.finance?.[deliveryMethod]?.available === true
              );
            })
          );
        });
        //else if delivery method is delivery but no delivery selected branch
      } else {
        productsToShowArray = [...products]?.filter((product) => {
          return (
            product?.isActive &&
            product?.finances?.find((finance) => {
              return (
                finance?.finance?.[deliveryMethod]?.available === true
                &&
                finance?.defaultItemPrice === true
              );
            })
          );
        });
      }
      //else if delivery method is either pickup or dinein, and if user selected branch
    } else if (userSelectedBranch && userSelectedBranch !== "") {
      productsToShowArray = [...products]?.filter((product) => {
        return (
          product?.isActive &&
          product?.finances?.find((finance) => {
            return (
              finance?.branch?.uuid === userSelectedBranch &&
              finance?.finance?.[deliveryMethod]?.available
            );
          }) &&
          product?.finances?.find((finance) => {
            return (
              finance?.defaultItemPrice &&
              finance?.finance?.[deliveryMethod]?.available
            );
          })
        );
      });
      //else, if delivery method is either pickup or dine in, but no user selected branch
    } else {
      productsToShowArray = [...products]?.filter((product) => {
        return (
          product?.isActive &&
          product?.finances?.find((finance) => {
            return (
              finance?.finance?.[deliveryMethod]?.available
              &&
              finance?.defaultItemPrice
            );
          })
        );
      });
    }

    //now keep combo items that have enough available products for required groups quantities
    let productsToShowArrayFinal = [];

    productsToShowArray.forEach((product) => {
      if (!product?.combo || (product?.combo && product?.combo?.length === 0)) {
        productsToShowArrayFinal.push(product)
      } else {
        let comboHasEnoughItems = true;

        product?.combo?.forEach((comboGroup) => {
          const requiredQty = comboGroup?.quantity == 0 || comboGroup?.quantity === null || comboGroup?.quantity === undefined ? -1 : comboGroup?.quantity;

          if (requiredQty !== -1) {
            let availableComboGroupItemsQuantitiesSum = 0;

            //check if available product combos are enough based on their max qty
            comboGroup?.productCombos?.forEach((comboGroupItem) => {

              const isComboGroupItemAvailable = productsToShowArray?.find((pr) => {
                return pr?.uuid === comboGroupItem?.product?.uuid
              });
              
              if (isComboGroupItemAvailable) {
                const comboGroupItemRequiredQty = comboGroupItem?.maxQty == 0 || comboGroupItem?.maxQty === null || comboGroupItem?.maxQty === undefined ? -1 : comboGroupItem?.maxQty;

                //if max qty is set
                if (comboGroupItemRequiredQty !== -1) {
                  availableComboGroupItemsQuantitiesSum += comboGroupItemRequiredQty;
                } else {
                  //if no max qty and user can choose as many as he wants of the item
                  availableComboGroupItemsQuantitiesSum += requiredQty;
                }
              }
            })

            if (availableComboGroupItemsQuantitiesSum < requiredQty) {
              comboHasEnoughItems = false;
            }
          }
        })
        if (comboHasEnoughItems) {
          productsToShowArrayFinal.push(product);
        }
      }
    })
    return productsToShowArrayFinal;
  }
};
