'use client';

import { usePathname } from 'next/navigation';
import CircularProgress from '@mui/material/CircularProgress';
import './Loader.css';

const Loader = ({ darkBg }) => {
 
  const pathname = usePathname();  
  const pathSegments = pathname.split('/');  
 
  const applyMainDesign = pathSegments[1] === "select-restaurant" || pathSegments[1] === "login" || pathSegments[1] === "signup" || (pathSegments[1] === "login" && pathSegments[2] === "verify");

  const root = document.documentElement;

  return (
    <div className={`loader-page-wrapper ${darkBg ? "dark" : ""} ${applyMainDesign ? "main-design" : ""}`}>
      <CircularProgress size={70} style={{ color: applyMainDesign ? "var(--main-color-main-design)" : "var(--main-color)" }} />
    </div>
  )
}

export default Loader

